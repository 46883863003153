import React from "react";
import Layout from "./layout/Layout";
import Homepage from "./pages/Homepage";
import Results from "./pages/Results";
import { Routes, Route } from "react-router-dom";


function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/"  element={<Layout />}>
        <Route path="results/" element={<Results />}>

        </Route>
      </Route>
      <Route path='*' element={<p style={{fontSize:50}}>Not page</p>} />
    </Routes>
  );
}

export default AppRoutes;
