import React from "react";
import Top from "../components/top/Top";
import About from "../components/about/About";
import Line from "../components/line/Line";
import Company from "../components/company/Company";
import Header from "../components/header/Header";

function Homepage() {
  return (
    <>
      <Header />
      <main className="main">
        <Line />
        <Top />
        <About />
        <Company />
      </main>
    </>
  );
}

export default Homepage;
