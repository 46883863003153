import queryString from "query-string";
import React from "react";
import { useLocation } from "react-router-dom";
import Videos from "../components/results/videos/Videos";

function Results() {
  const { search } = useLocation();
  const queryParams = queryString.parse(search);
  const key = Object.keys(queryParams)[0];

  return <>
  {key === 'videos' && <Videos />}
  
  </>;
}

export default Results;
