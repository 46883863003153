import AppRoutes from "./AppRoutes";
import Footer from "./components/footer/Footer";
import "rc-dropdown/assets/index.css";
import 'rc-slider/assets/index.css'
import "./styles/main.css";

function App() {
  return (
    <div className="App">
      <AppRoutes />
      <Footer />
    </div>
  );
}

export default App;
